import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
// export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
// export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const LIST_USER_REQUEST = "LIST_USER_REQUEST";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_FAILURE = "LIST_USER_FAILURE";

export const LIST_DASHBOARD_REQUEST = "LIST_DASHBOARD_REQUEST";
export const LIST_DASHBOARD_SUCCESS = "LIST_DASHBOARD_SUCCESS";
export const LIST_DASHBOARD_FAILURE = "LIST_DASHBOARD_FAILURE";

export const LIST_SUB_ADMIN_REQUEST = "LIST_SUB_ADMIN_REQUEST";
export const LIST_SUB_ADMIN_SUCCESS = "LIST_SUB_ADMIN_SUCCESS";
export const LIST_SUB_ADMIN_FAILURE = "LIST_SUB_ADMIN_FAILURE";

export const LIST_FULL_USER_REQUEST = "LIST_FULL_USER_REQUEST";
export const LIST_FULL_USER_SUCCESS = "LIST_FULL_USER_SUCCESS";
export const LIST_FULL_USER_FAILURE = "LIST_FULL_USER_FAILURE";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";

export const blockUnblockUser = (id, data, reFetch) => {
    console.log('data inside a updateMusic', data)
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_USER_REQUEST });

            const url = `${baseUrl}/user/admin/block/unblock/user/${id}/v1`;
            const response = await axios.put(url, { ...data }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_USER_SUCCESS, payload: { data: { ...response.data.data, categoryDetails: data.categoryDetails } } });
            toast("User Detail Updated Successfully.");
            reFetch()
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_USER_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listContactUs = (page, search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_USER_REQUEST });
            const url = `${baseUrl}/contact/admiin/submit/contact/v1?skip=${page}&search=${search}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_USER_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_USER_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const dashboardList = (start, end, year) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_DASHBOARD_REQUEST });
            const url = `${baseUrl}/user/admin/get/dashboard/list/v1?start=${start}&end=${end}&year=${year}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_DASHBOARD_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_DASHBOARD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listUser = (page, search, status, deleted, start, end) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_USER_REQUEST });
            const url = `${baseUrl}/user/admin/get/user/list/v1?skip=${page}&start=${start}&end=${end}&search=${search}&statu=${status === 'true' ? 1 : status === 'false' ? 2 : ''}&deleted=${deleted === 'true' ? 1 : deleted === 'false' ? 2 : ''}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_USER_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_USER_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listSubadmin = (page, search, status, deleted, start, end) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_SUB_ADMIN_REQUEST });
            const url = `${baseUrl}/user/admin/get/subadmin/list/v1?skip=${page}&start=${start}&end=${end}&search=${search}&statu=${status === 'true' ? 1 : status === 'false' ? 2 : ''}&deleted=${deleted === 'true' ? 1 : deleted === 'false' ? 2 : ''}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_SUB_ADMIN_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_SUB_ADMIN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserId = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: USER_DETAIL_REQUEST });
            const url = `${baseUrl}/user/admin/user/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: USER_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: USER_DETAIL_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};