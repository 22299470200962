import * as userActions from "./user.action";

export const userFeatureKey = "userInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    userList: [],
    userFullList: [],
    userDetail: {},
    userListTotal: 0
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {

        case userActions.UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.UPDATE_USER_SUCCESS:
            let findIndex = state.userList.findIndex(o => o._id === payload.data._id);
            state.userList.splice(findIndex, 1, payload.data);
            console.log('payload.data', payload.data, "state.userList", state.userList)
            return {
                ...state,
                loading: false
            }

        case userActions.UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // case userActions.DELETE_MUSIC_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        // case userActions.DELETE_MUSIC_SUCCESS:
        //     const removeArr = state.userList.filter(o => o._id !== payload);
        //     return {
        //         ...state,
        //         loading: false,
        //         userList: removeArr
        //     }

        // case userActions.DELETE_MUSIC_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errorMsg: payload
        //     }
        case userActions.LIST_DASHBOARD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LIST_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboardData: payload.data
            }

        case userActions.LIST_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.USER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetail: { ...payload.data, fcmList: payload.fcmList, YaadeinList: payload.YaadeinList }
            }

        case userActions.USER_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

            case userActions.LIST_FULL_USER_REQUEST:
                return {
                    ...state,
                    loading: true
                }
    
            case userActions.LIST_FULL_USER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    userFullList: payload.data
                }
    
            case userActions.LIST_FULL_USER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    errorMsg: payload
                }

        case userActions.LIST_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LIST_USER_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                userList: payload.data,
                userFullList: payload.fullList,
                userListTotal: payload.total,
            }

        case userActions.LIST_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }


        case userActions.LIST_SUB_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LIST_SUB_ADMIN_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                subadminList: payload.data,
                subadminListTotal: payload.total,
            }

        case userActions.LIST_SUB_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }
        default: return state;
    }
};