import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NavLink from "../ui-elements/NavLink";


const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");
  const loginUser = useSelector((state) => state.adminInfo.loginUser);


  const sidebarData = [
    {
      commonUrl: "/dashboard",
      visible: loginUser?.role === "admin" ? true : loginUser?.rules?.dashboard?.get,
      primary: { iconClass: "mdi-home", title: "Dashboard", url: "/dashboard" },
    },
    {
      // commonUrl: "/users",
      // visible: loginUser?.role === "admin" ? true : loginUser?.rules?.user?.get,
      visible: true,
      primary: {
        iconClass: "mdi-menu-down",
        title: "Users",
        // url: "/users",
      },
      secondary: [
        {
          iconClass: "mdi-account-multiple",
          title: "Users",
          url: "/users",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.user?.get,
        },
        {
          iconClass: "mdi-account-multiple",
          title: "Sub-admin",
          url: "/admins",
          visible: loginUser?.role === "admin" ? true : false,
        }
      ],
    },
    {
      commonUrl: "/yaadeins",
      visible: loginUser?.role === "admin" ? true : loginUser?.rules?.yaadein?.get,
      primary: {
        iconClass: "mdi-message-image",
        title: "Yaadeins",
        url: "/yaadeins",
      },
    },
    {
      // commonUrl: "/users",
      // visible: loginUser?.role === "admin" ? true : loginUser?.rules?.user?.get,
      visible: true,
      primary: {
        iconClass: "mdi-menu-down",
        title: "Settings",
        // url: "/users",
      },
      secondary: [
        {
          iconClass: "mdi-calendar",
          title: "Occasion",
          url: "/occasion",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.occasion?.get,
        },
        {
          iconClass: "mdi-cast-education",
          title: "Tutorial",
          url: "/tutorial",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.tutorial?.get,
        },
        {
          iconClass: "mdi-cash",
          title: "Price",
          url: "/price",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.price?.get,
        },
        {
          iconClass: "mdi-cogs",
          title: "App Setting",
          url: "/setting",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.appSetting?.get,
        },
        {
          iconClass: "mdi-account-plus",
          title: "Instruction Invites",
          url: "/instruction-invite",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.instructionInvite?.get,
        },
        {
          iconClass: "mdi-account-plus",
          title: "Content Settings",
          url: "/dynamic-data",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.dynamicData?.get,
        },
      ],
    },
    {
      // commonUrl: "/users",
      // visible: loginUser?.role === "admin" ? true : loginUser?.rules?.user?.get,
      visible: true,
      primary: {
        iconClass: "mdi-menu-down",
        title: "Stock Settings",
        // url: "/users",
      },
      secondary: [
        {
          iconClass: "mdi-folder-multiple-image",
          title: "Stock Image",
          url: "/stock-image",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.media?.image?.get
        },
        // { visible: loginUser?.role === "admin" ? true : loginUser?.rules?.media?.gif?.get, iconClass: "mdi-ghost", title: "Stock Gif", url: "/stock-gif" },
        {
          iconClass: "mdi-bookmark-music",
          title: "Selected Audio",
          url: "/selected-audio",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.media?.audio?.get
        },
        {
          iconClass: "mdi-folder-music",
          title: "Audio Genre",
          url: "/selected-category",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.media?.category?.get
        },
      ],
    },
    {
      commonUrl: "/payments",
      visible: loginUser?.role === "admin" ? true : loginUser?.rules?.payment?.get,
      primary: {
        iconClass: "mdi-credit-card-outline",
        title: "Transactions",
        url: "/payments",
      },
    },
    {
      commonUrl: "/contact-us",
      visible: loginUser?.role === "admin" ? true : loginUser?.rules?.contactUs?.get,
      primary: {
        iconClass: "mdi-credit-card-outline",
        title: "Contact Us",
        url: "/contact-us",
      },
    },
    {
      // commonUrl: "/",
      visible: loginUser?.role === "admin" ? true : (loginUser?.rules?.notification?.general?.get || loginUser?.rules?.notification?.marketing?.get ||  loginUser?.rules?.notitipsfication?.tips?.get),
      primary: {
        iconClass: "mdi-menu-down",
        title: "Notification",
        // url: "/",
      },
      secondary: [
        {
          iconClass: "mdi-bell-ring",
          title: "General Push Notification",
          url: "/push-notification",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.notification?.general?.get
        },
        {
          iconClass: "mdi-tooltip-edit-outline",
          title: "Tips Push Notification",
          url: "/tips-push-notification",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.notification?.tips?.get
        },
        {
          iconClass: "mdi-tooltip-edit-outline",
          title: "Marketing Notification",
          url: "/marketing-notification",
          visible: loginUser?.role === "admin" ? true : loginUser?.rules?.notification?.marketing?.get
        },
      ],
    }
  ];

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {sidebarData.map((data) => {
          return (
            <>
              {
                data?.visible && (
                  <>
                      <NavLink
                      isPathActive={isPathActive}
                      url={data.primary.url}
                      iconClass={data.primary.iconClass}
                      title={data.primary.title}
                      commonUrl={data.commonUrl}
                      child={data.secondary}
                    />
                  </>
                )
              }
            </>
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
