import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";

const Dashboard = lazy(() => import("./views/dashboard/Dashboard"));

const ContactUs = lazy(() => import("./views/user/ContactUs"));
const Users = lazy(() => import("./views/user/Users"));
const UserDetail = lazy(() => import("./views/user/UserDetail"));
const Admins = lazy(() => import("./views/user/Admins"));
const AdminDetail = lazy(() => import("./views/user/AdminDetail"));
const UpdateAdmin = lazy(() => import("./views/user/UpdateAdmin"));

const Occasion = lazy(() => import("./views/occasion/Occasion"));

const Yaadeins = lazy(() => import("./views/yaadein/Yaadeins"));
const YaadeinDetail = lazy(() => import("./views/yaadein/YaadeinDetail"));

const Payments = lazy(() => import("./views/payments/Payments"));

const StockImage = lazy(() => import("./views/stock-image/StockImage"));

// const StockGif = lazy(() => import("./views/stock-gif/StockGif"));

const SelectedAudio = lazy(() => import("./views/selected-audio/SelectedAudio"));
const SelectedAudioCategory = lazy(() => import("./views/selected-audio-category/SelectedAudioCategory"));
const PushNotification = lazy(() => import("./views/push-notification/PushNotification"));
const TipsPushNotification = lazy(() => import("./views/tips-notification/TipsNotification"));
const MarketingNotification = lazy(() => import("./views/marketing-notification/MarketingNotification"));
const InstructionInvite = lazy(() => import("./views/InstructionInvite/Instruction"));
const DynamicData = lazy(() => import("./views/dynamicData/index"));
const Setting = lazy(() => import("./views/Setting/index"));
const Price = lazy(() => import("./views/Price/index"));
const Tutorial = lazy(() => import("./views/Tutorial/index"));

const Error404 = lazy(() => import("./views/error-pages/Error404"));
const Error500 = lazy(() => import("./views/error-pages/Error500"));

const Login = lazy(() => import("./views/user-pages/Login"));

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

        <Route exact path="/contact-us" element={<ProtectedRoute><ContactUs /></ProtectedRoute>} />
        <Route exact path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
        <Route exact path="/admins" element={<ProtectedRoute><Admins /></ProtectedRoute>} />
        <Route exact path="/users/:id" element={<ProtectedRoute><UserDetail /></ProtectedRoute>} />
        <Route exact path="/admins/create" element={<ProtectedRoute><UpdateAdmin /></ProtectedRoute>} />
        <Route exact path="/admins/update/:id" element={<ProtectedRoute><UpdateAdmin /></ProtectedRoute>} />
        <Route exact path="/admins/:id" element={<ProtectedRoute><AdminDetail /></ProtectedRoute>} />

        <Route exact path="/occasion" element={<ProtectedRoute><Occasion /></ProtectedRoute>} />

        <Route exact path="/yaadeins" element={<ProtectedRoute><Yaadeins /></ProtectedRoute>} />
        <Route exact path="/yaadeins/:id" element={<ProtectedRoute><YaadeinDetail /></ProtectedRoute>} />

        <Route path="/payments" element={<ProtectedRoute><Payments /></ProtectedRoute>} />

        <Route path="/stock-image" element={<ProtectedRoute><StockImage /></ProtectedRoute>} />

        {/* <Route path="/stock-gif" element={<ProtectedRoute><StockGif /></ProtectedRoute>} /> */}

        <Route path="/selected-audio" element={<ProtectedRoute><SelectedAudio /></ProtectedRoute>} />
        <Route path="/selected-category" element={<ProtectedRoute><SelectedAudioCategory /></ProtectedRoute>} />
        <Route path="/push-notification" element={<ProtectedRoute><PushNotification /></ProtectedRoute>} />
        <Route path="/tips-push-notification" element={<ProtectedRoute><TipsPushNotification /></ProtectedRoute>} />
        <Route path="/marketing-notification" element={<ProtectedRoute><MarketingNotification /></ProtectedRoute>} />
        <Route path="/instruction-invite" element={<ProtectedRoute><InstructionInvite /></ProtectedRoute>} />
        <Route path="/dynamic-data" element={<ProtectedRoute><DynamicData /></ProtectedRoute>} />
        <Route path="/setting" element={<ProtectedRoute><Setting /></ProtectedRoute>} />
        <Route path="/price" element={<ProtectedRoute><Price /></ProtectedRoute>} />
        <Route path="/tutorial" element={<ProtectedRoute><Tutorial /></ProtectedRoute>} />

        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route exact path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
